import { ResolveFn } from '@angular/router';
import { ProcessService } from './process.service';
import { Process } from './process';
import { inject } from '@angular/core';

export const processResolverFn: ResolveFn<Process> = (route) => {
  const id = parseInt(route.params['processId']);
  const processService = inject(ProcessService);
  let process = processService.entityMap()[id];

  if (!process) {
    process = processService.addProcess({
      name: 'New Process',
      tags: ['return'],
    });
  }

  processService.activateProcess(process.id);
  return process;
};
