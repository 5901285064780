import { inject } from '@angular/core';
import { ProcessService } from './process.service';

export function injectActivatedProcessId() {
  return inject(ProcessService).activatedProcessId;
}

export function injectActivatedProcess() {
  return inject(ProcessService).activatedProcess;
}
