import { z } from 'zod';

export const AddProcessSchema = z.object({
  name: z.string().nonempty(),
  tags: z.array(z.string()),
});

export const PatchProcessSchema = z.object({
  name: z.string().nonempty().optional(),
  tags: z.array(z.string()).optional(),
});
