import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  addEntities,
  addEntity,
  removeEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { Process } from './process';
import { z } from 'zod';
import { AddProcessSchema, PatchProcessSchema } from './process.schemas';
import { computed, effect } from '@angular/core';

export const ProcessService = signalStore(
  { providedIn: 'root' },
  withState<{ activatedProcessId: number | null }>({ activatedProcessId: null }),
  withEntities<Process>(),
  withComputed((store) => ({
    nextId: computed(() => Math.max(0, ...store.entities().map((e) => e.id)) + 1),
    activatedProcess: computed<Process | null>(() => {
      const activeProcessId = store.activatedProcessId();
      if (activeProcessId === null) {
        return null;
      }
      return store.entities().find((e) => e.id === activeProcessId) ?? null;
    }),
  })),
  withMethods((store) => ({
    addProcess(add: z.infer<typeof AddProcessSchema>) {
      const parsed = AddProcessSchema.parse(add);
      const process: Process = {
        name: parsed.name,
        id: store.nextId(),
        createdAt: Date.now(),
        tags: parsed.tags,
      };
      patchState(store, addEntity(process));
      return process;
    },
    activateProcess(id: number) {
      patchState(store, {
        ...updateEntity({ id, changes: { activatedAt: Date.now() } }),
        activatedProcessId: id,
      });
    },
    patchProcess(id: number, changes: z.infer<typeof PatchProcessSchema>) {
      patchState(store, updateEntity({ id, changes: PatchProcessSchema.parse(changes) }));
    },
    removeProcess(id: number) {
      patchState(store, removeEntity(id));
    },
  })),
  withHooks((store) => ({
    onInit() {
      const entitiesStr = localStorage.getItem('ProcessEntities');
      if (entitiesStr) {
        const entities = JSON.parse(entitiesStr);
        patchState(store, addEntities(entities));
      }

      effect(() => {
        const state = store.entities();
        localStorage.setItem('ProcessEntities', JSON.stringify(state));
      });
    },
  })),
);
