/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ResponseArgsOfReceiptDTO } from '../models/response-args-of-receipt-dto';
import { ResponseArgsOfIEnumerableOfReceiptDTO } from '../models/response-args-of-ienumerable-of-receipt-dto';
import { GenerateCollectiveReceiptsArgs } from '../models/generate-collective-receipts-args';
import { ResponseArgsOfIEnumerableOfString } from '../models/response-args-of-ienumerable-of-string';
import { DateRange } from '../models/date-range';
import { ListResponseArgsOfReceiptListItemDTO } from '../models/list-response-args-of-receipt-list-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfReceiptItemListItemDTO } from '../models/list-response-args-of-receipt-item-list-item-dto';
import { ResponseArgsOfIEnumerableOfValueTupleOfLongAndReceiptTypeAndEntityDTOContainerOfReceiptDTO } from '../models/response-args-of-ienumerable-of-value-tuple-of-long-and-receipt-type-and-entity-dtocontainer-of-receipt-dto';
import { ReceiptOrderItemSubsetReferenceValues } from '../models/receipt-order-item-subset-reference-values';
@Injectable({
  providedIn: 'root',
})
class ReceiptService extends __BaseService {
  static readonly ReceiptQueryReceiptSettingsPath = '/receipt/s/settings';
  static readonly ReceiptGetReceiptPath = '/receipt/{receiptId}';
  static readonly ReceiptGenerateCollectiveReceiptsPath = '/receipt/collectivereceipts';
  static readonly ReceiptGenerateCollectiveReceiptsSimulationSummaryPath = '/receipt/collectivereceipts/simulationsummary';
  static readonly ReceiptQueryReceiptPath = '/receipt/s';
  static readonly ReceiptQueryReceiptItemPath = '/receipt/item/s';
  static readonly ReceiptCreateShippingNotePath = '/receipt/shippingnote/fromorder';
  static readonly ReceiptCreateShippingNote2Path = '/receipt/shippingnote/fromitems';
  static readonly ReceiptCreateInvoicePath = '/receipt/invoice/fromorder';
  static readonly ReceiptCreateInvoice2Path = '/receipt/invoice/fromitems';
  static readonly ReceiptGetReceiptsByOrderItemSubsetPath = '/order/orderitem/orderitemsubset/receipts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Belege-Settings
   */
  ReceiptQueryReceiptSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/receipt/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Belege-Settings
   */
  ReceiptQueryReceiptSettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.ReceiptQueryReceiptSettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * @param params The `ReceiptService.ReceiptGetReceiptParams` containing the following parameters:
   *
   * - `receiptId`:
   *
   * - `eagerLoading`:
   */
  ReceiptGetReceiptResponse(params: ReceiptService.ReceiptGetReceiptParams): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/receipt/${encodeURIComponent(String(params.receiptId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.ReceiptGetReceiptParams` containing the following parameters:
   *
   * - `receiptId`:
   *
   * - `eagerLoading`:
   */
  ReceiptGetReceipt(params: ReceiptService.ReceiptGetReceiptParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReceiptGetReceiptResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfReceiptDTO)
    );
  }

  /**
   * Sammelrechnungen erstellen
   * @param payload undefined
   */
  ReceiptGenerateCollectiveReceiptsResponse(payload: GenerateCollectiveReceiptsArgs): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/collectivereceipts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>;
      })
    );
  }
  /**
   * Sammelrechnungen erstellen
   * @param payload undefined
   */
  ReceiptGenerateCollectiveReceipts(payload: GenerateCollectiveReceiptsArgs): __Observable<ResponseArgsOfIEnumerableOfReceiptDTO> {
    return this.ReceiptGenerateCollectiveReceiptsResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfReceiptDTO)
    );
  }

  /**
   * Erstellung der Sammelrechnungen simulieren
   * @param dateRange undefined
   */
  ReceiptGenerateCollectiveReceiptsSimulationSummaryResponse(dateRange: DateRange): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dateRange;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/collectivereceipts/simulationsummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfString>;
      })
    );
  }
  /**
   * Erstellung der Sammelrechnungen simulieren
   * @param dateRange undefined
   */
  ReceiptGenerateCollectiveReceiptsSimulationSummary(dateRange: DateRange): __Observable<ResponseArgsOfIEnumerableOfString> {
    return this.ReceiptGenerateCollectiveReceiptsSimulationSummaryResponse(dateRange).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfString)
    );
  }

  /**
   * Belege
   * @param params The `ReceiptService.ReceiptQueryReceiptParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   */
  ReceiptQueryReceiptResponse(params: ReceiptService.ReceiptQueryReceiptParams): __Observable<__StrictHttpResponse<ListResponseArgsOfReceiptListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReceiptListItemDTO>;
      })
    );
  }
  /**
   * Belege
   * @param params The `ReceiptService.ReceiptQueryReceiptParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   */
  ReceiptQueryReceipt(params: ReceiptService.ReceiptQueryReceiptParams): __Observable<ListResponseArgsOfReceiptListItemDTO> {
    return this.ReceiptQueryReceiptResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfReceiptListItemDTO)
    );
  }

  /**
   * Belegeposten
   * @param params The `ReceiptService.ReceiptQueryReceiptItemParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   */
  ReceiptQueryReceiptItemResponse(params: ReceiptService.ReceiptQueryReceiptItemParams): __Observable<__StrictHttpResponse<ListResponseArgsOfReceiptItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/item/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReceiptItemListItemDTO>;
      })
    );
  }
  /**
   * Belegeposten
   * @param params The `ReceiptService.ReceiptQueryReceiptItemParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   */
  ReceiptQueryReceiptItem(params: ReceiptService.ReceiptQueryReceiptItemParams): __Observable<ListResponseArgsOfReceiptItemListItemDTO> {
    return this.ReceiptQueryReceiptItemResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfReceiptItemListItemDTO)
    );
  }

  /**
   * Lieferschein erstellen
   * @param params The `ReceiptService.ReceiptCreateShippingNoteParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateShippingNoteResponse(params: ReceiptService.ReceiptCreateShippingNoteParams): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.orderId;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/shippingnote/fromorder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      })
    );
  }
  /**
   * Lieferschein erstellen
   * @param params The `ReceiptService.ReceiptCreateShippingNoteParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateShippingNote(params: ReceiptService.ReceiptCreateShippingNoteParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReceiptCreateShippingNoteResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfReceiptDTO)
    );
  }

  /**
   * Lieferschein erstellen
   * @param params The `ReceiptService.ReceiptCreateShippingNote2Params` containing the following parameters:
   *
   * - `orderItemStatusIds`: Bestellpostenstatus PKs
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateShippingNote2Response(params: ReceiptService.ReceiptCreateShippingNote2Params): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.orderItemStatusIds;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/shippingnote/fromitems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>;
      })
    );
  }
  /**
   * Lieferschein erstellen
   * @param params The `ReceiptService.ReceiptCreateShippingNote2Params` containing the following parameters:
   *
   * - `orderItemStatusIds`: Bestellpostenstatus PKs
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateShippingNote2(params: ReceiptService.ReceiptCreateShippingNote2Params): __Observable<ResponseArgsOfIEnumerableOfReceiptDTO> {
    return this.ReceiptCreateShippingNote2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfReceiptDTO)
    );
  }

  /**
   * Rechnung erstellen
   * @param params The `ReceiptService.ReceiptCreateInvoiceParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateInvoiceResponse(params: ReceiptService.ReceiptCreateInvoiceParams): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.orderId;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/invoice/fromorder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      })
    );
  }
  /**
   * Rechnung erstellen
   * @param params The `ReceiptService.ReceiptCreateInvoiceParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateInvoice(params: ReceiptService.ReceiptCreateInvoiceParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReceiptCreateInvoiceResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfReceiptDTO)
    );
  }

  /**
   * Rechnung erstellen
   * @param params The `ReceiptService.ReceiptCreateInvoice2Params` containing the following parameters:
   *
   * - `orderItemStatusIds`: Bestellpostenstatus PKs
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateInvoice2Response(params: ReceiptService.ReceiptCreateInvoice2Params): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.orderItemStatusIds;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/receipt/invoice/fromitems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>;
      })
    );
  }
  /**
   * Rechnung erstellen
   * @param params The `ReceiptService.ReceiptCreateInvoice2Params` containing the following parameters:
   *
   * - `orderItemStatusIds`: Bestellpostenstatus PKs
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  ReceiptCreateInvoice2(params: ReceiptService.ReceiptCreateInvoice2Params): __Observable<ResponseArgsOfIEnumerableOfReceiptDTO> {
    return this.ReceiptCreateInvoice2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfReceiptDTO)
    );
  }

  /**
   * Zugeordnete Belege
   * @param params The `ReceiptService.ReceiptGetReceiptsByOrderItemSubsetParams` containing the following parameters:
   *
   * - `payload`: Bestellpostenstatus PK und Belegart
   *
   * - `locale`: Lokalisierung (optional)
   */
  ReceiptGetReceiptsByOrderItemSubsetResponse(params: ReceiptService.ReceiptGetReceiptsByOrderItemSubsetParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfLongAndReceiptTypeAndEntityDTOContainerOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/orderitem/orderitemsubset/receipts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfLongAndReceiptTypeAndEntityDTOContainerOfReceiptDTO>;
      })
    );
  }
  /**
   * Zugeordnete Belege
   * @param params The `ReceiptService.ReceiptGetReceiptsByOrderItemSubsetParams` containing the following parameters:
   *
   * - `payload`: Bestellpostenstatus PK und Belegart
   *
   * - `locale`: Lokalisierung (optional)
   */
  ReceiptGetReceiptsByOrderItemSubset(params: ReceiptService.ReceiptGetReceiptsByOrderItemSubsetParams): __Observable<ResponseArgsOfIEnumerableOfValueTupleOfLongAndReceiptTypeAndEntityDTOContainerOfReceiptDTO> {
    return this.ReceiptGetReceiptsByOrderItemSubsetResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfValueTupleOfLongAndReceiptTypeAndEntityDTOContainerOfReceiptDTO)
    );
  }
}

module ReceiptService {

  /**
   * Parameters for ReceiptGetReceipt
   */
  export interface ReceiptGetReceiptParams {
    receiptId: number;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReceiptQueryReceipt
   */
  export interface ReceiptQueryReceiptParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for ReceiptQueryReceiptItem
   */
  export interface ReceiptQueryReceiptItemParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for ReceiptCreateShippingNote
   */
  export interface ReceiptCreateShippingNoteParams {

    /**
     * Bestellung PK
     */
    orderId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * eagerLoading (optional)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ReceiptCreateShippingNote2
   */
  export interface ReceiptCreateShippingNote2Params {

    /**
     * Bestellpostenstatus PKs
     */
    orderItemStatusIds: Array<number>;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * eagerLoading (optional)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ReceiptCreateInvoice
   */
  export interface ReceiptCreateInvoiceParams {

    /**
     * Bestellung PK
     */
    orderId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * eagerLoading (optional)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ReceiptCreateInvoice2
   */
  export interface ReceiptCreateInvoice2Params {

    /**
     * Bestellpostenstatus PKs
     */
    orderItemStatusIds: Array<number>;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * eagerLoading (optional)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ReceiptGetReceiptsByOrderItemSubset
   */
  export interface ReceiptGetReceiptsByOrderItemSubsetParams {

    /**
     * Bestellpostenstatus PK und Belegart
     */
    payload: ReceiptOrderItemSubsetReferenceValues;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }
}

export { ReceiptService }
